import { Link, useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import getGatsbyImage from 'utils/getGatsbyImage'
import Button from '../../../../UI/Button'

const Header = styled.div`
  position: relative;
  background-image: linear-gradient(
  90deg
  ,#45106B, #3e0e60);
  height: 240px;
  > :first-child {
    height: 100%;
    max-width: 1100px;
    margin: 0 auto;
    display: flex;
  }
  color: #fff;

`

const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  width: 60%;
  align-items: flex-start;
  h1 {
    flex: 1;
    display: flex;
    align-items: center;
    margin: 0;
  }
  > :last-child {
    margin-top: auto;

    > :first-child { width: 100%; }
  }
  z-index: 2;
  @media (max-width: 1120px) {
    width: 100%;
    align-items: center;
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
    padding: 20px;
    > :last-child {
      margin-top: auto;
    }
  }
`

const BigTitle = styled.h1`
  letter-spacing: 2px;
  text-shadow: none;
  font-size: 2.5rem;
  font-family: 'Rammetto One';
  @media (max-width: 400px) {
    font-size: 2.5rem;
  }
`

const MaskedImage = styled.div`
  position: relative;
  width: 40%;
  align-self: stretch;
  height: 100%;
  overflow: hidden;
  > :first-child {
    position: absolute;
    width: 100%;
    height: 102%;
    top: 0;
    left: 0;
    object-fit: cover;
    clip-path: polygon(25% 0, 100% 0, 100% 100%, 0 100%, 0 100%)
  }
  @media (max-width: 800px) {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    > :first-child {
      ::after {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(0,0,0,0.3);
      }
    }
  }
`

const BlogHero = () => {
  const { file } = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "blog-header-image.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 660)
        }
      }
    }
  `)

  return <Header>
    <div>
      <TitleSection>
        <BigTitle>Night Zookeeper Blog</BigTitle>
        <Link to='/parents'><Button theme='confirm' size='regular'>Our Program</Button></Link>
      </TitleSection>
      <MaskedImage>
        <GatsbyImage image={getGatsbyImage(file)} alt='Child enjoying Night Zookeeper' />
      </MaskedImage>
    </div>
  </Header>
}

export default BlogHero
